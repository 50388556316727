
.inputbox label {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
}

[type="checkbox"] {
  appearance: none;
  position: relative;
  border: max(0.125em, 0.1em) solid #b8b8b9;
  border-radius: 1.25em;
  width: 2.75em;
  height: 1.5em;
  cursor: pointer;
  background-color: white;
}

[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 0;
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  transform: scale(0.8);
  background-color: #b8b8b9;
  transition: left 250ms linear;
}

[type="checkbox"]:checked {
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
}

[type="checkbox"]:checked::before {
  background-color: white;
  left: 1.25em;
}

[type="checkbox"]:disabled {
  border-color: #b8b8b9;
  opacity: 0.7;
  cursor: not-allowed;
}

[type="checkbox"]:checked:disabled {
  background-color: #ddd;
}

[type="checkbox"]:disabled:before {
  background-color: #b8b8b9;
}

[type="checkbox"]:disabled + span {
  opacity: 0.6;
  cursor: not-allowed;
}

[type="checkbox"]:focus-visible {
  outline-offset: max(0.125em, 0.1em);
  outline: max(0.125em, 0.1em) solid var(--theme-primary-color);
}

[type="checkbox"]:enabled:hover {
  box-shadow: 0 0 0 max(0.25em, 0.2em) #fcfcfc;
}

.inputbox {
  display: inline-block;
  vertical-align: middle;

  &.theme-secondary label {
    [type="checkbox"]:checked {
      background-color: var(--theme-secondary-color);
      border-color: var(--theme-secondary-color);
    }
    [type="checkbox"]:focus-visible {
      outline: max(0.125em, 0.1em) solid var(--theme-secondary-color);
    }
  }
}
