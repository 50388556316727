
.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  min-width: 60px;
  box-sizing: border-box;
}

.button {
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  position: relative;
  background: yellow;
  margin: 4px 2.5px;
  width: calc(100% - 5px);
  box-sizing: border-box;
  cursor: pointer;
  text-indent: 0;
  padding: 0px 6px;
  border-radius:5px;
  border:1px solid var(--theme-primary-color);
  background-color: #fff;
  color: var(--theme-primary-color);
  font: 500 14px NanumSquareAc;
  line-height: 24px;
  &.primary {
    background-color: var(--theme-primary-color);
    color:#fff;
  }
  &.red {
    color: #fff;
    border: 1px solid red;
    background-color: red;
  }
  &.black {
    color: #fff;
    border: 1px solid #000;
    background-color: #000;
  }
  &.outline-black {
    color: #000;
    border: 1px solid #000;
  }
  &.big {
    min-width: 60px;
    padding: 0px 20px;
    line-height: 30px;
  }
  &.small {
    padding: 0px 4px;
    line-height: 20px;
  }
  &.primary,
  &.red,
  &.black {
    &:disabled {
      color:#FFF;
      background-color: #aaa !important;
      border-color: #999 !important;
      cursor: not-allowed;
    }
  }
  &:disabled {
    color:#999;
    border-color: #999 !important;
    cursor: not-allowed;
  }
}
